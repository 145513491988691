<template>
    <appheader isBasicHeader></appheader>
    <div class="context">
       <div class="main">
          <h1>ESPACE PRIVÉ</h1>
          <h2>Réservé aux clients <a href="https://idtonic.com">Idtonic</a></h2>
          <h2>Toutes vos ressources projet au même endroit</h2>
          <p v-if="isLoading">Loading...</p>
          <p style='color:red;background-color: white;padding: 3px 5px;' v-else-if="!isLoading && error">{{ error }}</p>
          <!--<p v-else>Remplissez ce formulaire pour vous connecter.</p>-->
          <form @submit.prevent="submitLogin" class="form-horizontal col-md-offset-3">
              <div class="form-group">
                <div class="col-md-8 col-xs-12 center-block text-center"><input name="login" placeholder="Idenfiant" class="form-control" type="text" id="login" v-model.trim="enteredLogin" required /></div>
              </div>
              <div class="form-group">
                <div class="col-md-8 col-xs-12 center-block text-center"><input name="password" placeholder="Mot de passe" class="form-control" type="password" id="password" v-model.trim="enteredPassword"
            required/></div>
              </div>
              <div class="form-group">
              <div class="col-md-8 col-xs-12 center-block text-center"><input  class="btn btn-success" type="submit" value="Connexion"/></div>
              </div>
          </form>
        </div>
    </div>

    <!-- WAVE ANIMATION-->
    <div v-if="animation=='waves'" class="wavescontainer">
      <div>
      <svg class="wasf" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
      </defs>
      <g class="parallax">
      <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
      <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
      <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
      <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
      </g>
      </svg>
    </div>
    </div>

    <!-- CIRCLE ANIMATION-->
    <div v-if="animation=='circles'" class="area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

    <!-- 
      Je garde ce code pour l'exemple de base-card (template UI) /slot
      <base-card>
        <form>
          <input
            type="text"
            placeholder="Entrez votre identifiant"
          />    
          <input
            type="password"
            placeholder="Entrez votre mot de passe"
          />
          <base-button>Envoyer</base-button>
        </form>
    </base-card>
     -->
</template>

<script>
import Header from "@/components/UI/Header.vue";
import Misc from "@/functions.js"

export default {
  components:{
    'appheader':Header
  },
  data() {
    return {
      animation: Misc.animation, /* [none,waves,circles] */
      action: "",
      enteredLogin: "",
      enteredPassword: "",
      isLoading: false,
      projet: "",
      error: null,
    };
  },
  methods: {
    submitLogin() {
      this.error = null;
      if (this.enteredPassword === "" || this.enteredLogin === "") {
        this.error = "Veuillez remplir le formulaire";
        return;
      }
      this.isLoading = true;
      fetch(Misc.getAPIurl() + "login.php", { method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          login: this.enteredLogin,
          password: this.enteredPassword,
        }),
      })
        .then((response) => {
          // console.log(response);
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Could not save data!');
          }
        })
        .then((data) => {
          console.log("DATA",data);
          this.isLoading = false;
          if (data.error) {
            this.error = data.error;
          } else {
            this.projet = data.projet;
            this.islead = data.islead;
            // GO tO the url projet
            this.greet();
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.error = "Failed to identify you - please try again";
        });
    },
    greet() {
      var config = { 'login': this.enteredLogin, 'projet': this.projet, 'islead': this.islead, 'reference': this.reference };
      Misc.saveConfig(config);
      this.$router.push('/dashboard');
    },
  },
  mounted() {
    Misc.toggleBodyClass('addClass', 'login');
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("r")) {
      this.action = urlParams.get("r");
      console.log("Login.vue - monted() > action",this.action);
    }
    // Je mets le login par défaut dans la boite de login
    if (Misc.getConfig()) this.enteredLogin = Misc.getConfig().login;
  },
  unmounted() {
    Misc.toggleBodyClass('removeClass', 'login');
  }
};
</script>

<style>
  @import '../assets/bootstrap-3/css/bootstrap.min.css';
  @import '../assets/styles/login.css';
</style>