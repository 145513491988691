<template>
  <appheader isBasicHeader></appheader>
  <div class="container">
    <div class="row">
        <div class="col-xs-12 col-sm-11 col-sm-offset-1 col-md-11 col-md-offset-1 ">
          <div class="context">
            <div class="main">
              <h1>Oops</h1>
              <h2>La page que vous recherchez semble introuvable...</h2>
              <hr>
              <div class="col-xs-12 center-block text-center">
                <router-link class="btn btn-success" to="/">Espace projet</router-link>
              </div>
            </div>
        </div>
      </div>
  </div>
</div>
</template>

<script>
import Header from "@/components/UI/Header.vue";
import Misc from "@/functions.js"

export default {
    name: '404',
    components:{
        'appheader':Header,
    },
    data() {
      return {
        'lead_visitor':false
      }
    },
    mounted() {
      Misc.toggleBodyClass('addClass', 'login');
    },
    unmounted() {
      Misc.toggleBodyClass('removeClass', 'login');
    }
  }
</script>

<style scoped>
  @import '../assets/bootstrap-3/css/bootstrap.min.css';
  @import '../assets/styles/login.css';
</style>