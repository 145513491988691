<template>
    <section class="page-entry" style="font-size: 14px;line-height: 20px;">
        <div class="backdrop"></div>
        <div class="features-entry__inner">
            <div class="row">
                <h2 class="heading-2 heading-2--white features-entry__title h-align--center">2 offres de maintenance</h2>
                <br />
                <div class="col-md-6">
                    <h4 class="heading-4 heading-4--white">Maintenance annuelle</h4>
                    <p>
                        Adapt&eacute; aux objectifs de maintenance de longue dur&eacute;e (1 an):
                    </p>
                    <ul class="bullet-gray">
                        <li>R&eacute;solution de bugs dans les 48h</li>
                        <li>Sauvegarde</li>
                        <li>
                            Maintenance r&eacute;guli&egrave;re (mises &agrave; jour de plugin &amp; de core WP)
                        </li>
                        <li>Gestion de contenu</li>
                        <li>
                            Optimisation constante du r&eacute;f&eacute;rencement naturel
                        </li>
                    </ul>
                    <p>
                        Maintenance annuelle : <a href="https://idtonic.com/fichiers/maintenance-siteweb.pdf">site web vitrine / institutionnel</a> ou <a href="https://idtonic.com/fichiers/maintenance-ecommerce.pdf">e-commerce</a>
                    </p>
                </div>
                <div class="col-md-6">
                    <h4 class="heading-4 heading-4--white">Assistance ponctuelle</h4>
                    <p>
                        Achat d'un nombre d'heures pr&eacute;d&eacute;finis utile pour :
                    </p>
                    <ul class="bullet-gray">
                        <li>Remise en &eacute;tat et/ou optimisation d'un site Internet</li>
                        <li>
                            Migration de serveur, int&eacute;gration de modules, passage au https
                        </li>
                        <li>Modification de design</li>
                        <li>
                            Am&eacute;lioration de la vitesse de chargement (cache, CDN),&nbsp;
                        </li>
                        <li>Evolutions...</li>
                    </ul>
                    <p>
                        <a href="https://idtonic.com/fichiers/tarifs-maintenance.pdf">Assistance &amp; interventions</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
