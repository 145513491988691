<template>
  <Header :isBasicHeader="false" />
  <section class="page-entry" style="background: #fff;font-size: 14px;line-height: 20px;">
    <div class="backdrop"></div>
    <div class="features-entry__inner">
      <div style="float:right"><button class="button button--small button--light uppercase--small" v-on:click="logout()">Se déconnecter</button></div>
      <h3 class="heading-3 features-entry__title" style="text-transform:capitalize">Projet {{projet}}</h3>
      <h4 class="heading-4">Bienvenue {{enteredLogin}} dans votre espace projet<!-- pour une collaboration optimale--></h4>
      Vous retrouvez l'ensemble des ressources (documents, liens, etc) ainsi que les temps passés dans le cadre de la maintenance.
      <div id="dynamic-component-demo" class="demo">
        <button
          v-for="tab in tabs"
          v-bind:key="tab"
          v-bind:class="['tab-button', { active: currentTab === tab }]"
          v-on:click="currentTab = tab"
        >
          {{ tab }}
        </button>
      </div>
      <div v-show="currentTabComponent == 'tab-maintenance' " id="tab-maintenance" class="demo-tab">
          <h4>Temps restant sur le forfait : <strong>{{remaining}}h</strong></h4>
          <h4>Tâches effectuées</h4>
          <table class="table table-striped table-bordered">
              <thead>
                  <tr>
                      <th>Date</th>
                      <th>Description</th>
                      <th>Temps passé</th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="tache in taches" :key="tache.tache">
                      <td>{{ formatDate(tache.date) }}</td>
                      <td>{{tache.tache}}</td>
                      <td>{{formatHeure(tache.temps)}}</td>
                  </tr>
              </tbody>
          </table>
      </div>
      <div v-show="currentTabComponent == 'tab-facturation'" id="tab-facturation" class="demo-tab">
          <h2>Factures</h2>
          <div v-for="achat in achats" :key="achat.txt" class="tache">
              <div class="tache-text">{{ formatDate(achat.date) }} {{achat.text}} <br />{{formatHeure(achat.temps)}}</div>
          </div>
      </div>
      <div v-show="currentTabComponent == 'tab-infos-projet'" id="tab-infos-projet" class="demo-tab">
          <div v-html="userHtml"></div>
      </div>     
    </div>
  </section>
  <Maintenance />
  <Footer />
</template>

<script>
import Header from "@/components/UI/Header.vue";
import Footer from "@/components/UI/Footer.vue";
import Maintenance from "@/components/UI/Maintenance.vue";
import Misc from "@/functions.js";
import formatDateMixin from '@/mixins/formatDateMixin.js';
import {useRoute} from 'vue-router'
import {computed} from 'vue'

export default {
  name:"Dashboard",
  components:{
    Header,
    Maintenance,
    Footer
  },
  data() {
    return {
      currentTab: 'maintenance',
      tabs: ['Maintenance', 'Facturation', 'Infos-Projet'],
      action: "",
      enteredLogin: "",
      isLoading: false,
      projet: "",
      taches: [],
      achats: [],
      remaining: 0,
      userHtml: "",
      error: null,
      path:"/"
    };
  },
  methods: {
    logout: function() {
        Misc.logout();
        this.$router.push('/');
    },
    getInfoProject() {
      this.error = null;
      this.isLoading = true;
      fetch(Misc.getAPIurl() + "getpages.php", { method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          projet: this.projet
        }),
      })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Could not save data!');
        }
      })
      .then((data) => {
        this.isLoading = false;
        if (data.error) {
          this.error = data.error;
          console.log("DATA ERROR",data.error);
        } else {
          this.userHtml = data.htmlContent;
          console.log("DATA SUCCESS",data.htmlContent);
        }
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
        this.error = "Failed to retreive HTML";
      });
    },
    getWorklog() {
      this.error = null;
      this.isLoading = true;
      fetch(Misc.getAPIurl() + "worklog.php", { method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          projet: this.projet
        }),
      })
        .then((response) => {
          // console.log(response);
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Could not save data!');
          }
        })
        .then((data) => {
          console.log(data);
          this.isLoading = false;
          if (data.error) {
            this.error = data.error;
          } else {
            this.achats = data.achats;
            for (let i=0;i<this.achats.length;i++){
              this.remaining = this.remaining + this.achats[i].temps;
            } 
            this.taches = data.taches;
            for (let i=0;i<this.taches.length;i++){
              this.remaining = this.remaining - this.taches[i].temps;
            } 
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
          this.error = "Failed to get worklog - please try again";
        });
    },
    
  },
  mounted() {
    const route = useRoute();
    this.path = computed(() =>route.path);
    if (Misc.getConfig()) {
        if (Misc.getConfig().projet !== "") {
        this.projet = Misc.getConfig().projet;
        this.getWorklog();
        this.getInfoProject();
      }
      this.enteredLogin = Misc.getConfig().login;
    }
    this.path = this.$parent.path;
  },
  computed: {
    currentTabComponent() {
      console.log("currentTabComponent >>", this.currentTab.toLowerCase());
      return 'tab-' + this.currentTab.toLowerCase()
    }
  },
  mixins: [formatDateMixin],
};
</script>

<style scoped>
  @import '../assets/bootstrap-3/css/bootstrap.min.css';
  @import '../assets/styles/styles.css';

.tab-button {
  padding: 6px 10px;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border: 1px solid #ccc;
  cursor: pointer;
  background: #f0f0f0;
  margin-bottom: -1px;
  margin-right: -1px;
}
.tab-button:hover {
  background: #e0e0e0;
}
.tab-button.active {
  background: #e0e0e0;
}
.demo-tab {
  border: 1px solid #ccc;
  padding: 10px;
}

</style>