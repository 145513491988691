export default {
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', {dateStyle: 'short'}).format(date);
        },
        formatHeure(nbHeures) {
            var returnText = nbHeures + " heure";
            if (nbHeures>=2) returnText = returnText + "s";
            return returnText;
        }
    }
}
