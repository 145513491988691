import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Contact from "@/views/Contact.vue";
import FAQ from "@/views/FAQ.vue";
import Merci from "@/views/Merci.vue";
import Documentation from "@/views/Documentation.vue";
import Tchatbot from "@/views/Tchatbot.vue";
import NotFound from "@/views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/merci",
    name: "Merci",
    component: Merci,
  },
  {
    path: "/foire-aux-questions",
    name: "Foire aux questions",
    component: FAQ,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/documentation/:doc",
    name: "Documentation",
    component: Documentation,
  },
  {
    path: "/tchatbot",
    name: "Tchatbot",
    component: Tchatbot,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;