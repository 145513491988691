<template>
  <Header :isBasicHeader="false" />
  <div id="preview-window-wrapper" style="width:100%;height:100%">
    <iframe v-bind:src="'https://app.idtonic.fr/api/tchat/?r=' + $route.params.reference" id="preview-window" frameborder="0" noresize="noresize" style="width:100%;height:100%"/>
  </div>
</template>
<script>
import Header from "@/components/UI/Header.vue";
import Misc from "@/functions.js"

export default {
  name: 'Documentation',
    components:{
        Header,
    },
    mounted() {
      console.log(Misc.getConfig());
      Misc.toggleBodyClass('addClass', 'iframe');
    },
    unmounted() {
      Misc.toggleBodyClass('removeClass', 'iframe');
    }
};
</script>
<style>
  @import '../assets/bootstrap-3/css/bootstrap.min.css';
  /* @import '../assets/styles/iframe.css'; */
</style>