const Misc = {
  data() {
    return {
      animation: "waves",
      version:"1.0.1"
    };
  },
  getAPIurl: function(){
    return "https://app.idtonic.fr/api/";
  },
  formatDate(dateString) {
    const date = new Date(dateString);
        // Then specify how you want your dates to be formatted
    return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
  },
  isUserConnected: function() {
    return (this.getConfig() !== null);
  },
  isUserLead: function() {
    return (this.isUserConnected() ? this.getConfig().islead : false);
  },
  toggleBodyClass(addRemoveClass, className) {
    const el = document.body;
    if (addRemoveClass === 'addClass') {
      el.classList.add(className);
    } else {
      el.classList.remove(className);
    }
  },
  // Config 
  getConfig: function(){
    if(localStorage.getItem("config")){
      return JSON.parse(localStorage.getItem("config"));
    } else {
      return null;
    }
  },
  saveConfig: function(config){
      if(localStorage.getItem("config")){
          localStorage.removeItem("config");
      }
      localStorage.setItem("config", JSON.stringify(config));
      return
  },
  // Logout
  logout: function(){
    if(localStorage.getItem("config")){
      localStorage.removeItem("config");
    }
    return true;
  }
}
export default Misc