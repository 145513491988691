<template>
  <appheader isBasicHeader></appheader>
    <div class="context">
       <div class="main">
        <div v-if="!userconnected">
          <h1>ESPACE PRIVÉ</h1>
          <h2>Réservé aux clients <a href="https://idtonic.com">Idtonic</a></h2>
          <h2>Toutes vos ressources projet au même endroit</h2>
          <hr>
          <div class="col-xs-12 center-block text-center">
            <router-link class="btn btn-success" to="/login">Se connecter</router-link>
          </div>
        </div>
        <div v-else>
          <h1>ESPACE PROJET</h1>
          <h2>Bonjour <span style="text-transform: capitalize;">{{enteredLogin}}</span> 👋</h2>
          <h2>Toutes vos ressources projet au même endroit</h2>
          <hr>
          <div class="col-xs-6 center-block text-center">
            <router-link class="btn btn-success" to="/dashboard">Tableau de bord</router-link>
          </div>
          <div class="col-xs-6 center-block text-center">
            <router-link class="btn btn-success" to="/foire-aux-questions">Foire aux questions</router-link>
          </div>
        </div>

      </div>
    </div>

    <!-- WAVE ANIMATION-->
    <div v-if="animation=='waves'" class="wavescontainer">
      <div>
      <svg class="wasf" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"></path>
      </defs>
      <g class="parallax">
      <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7"></use>
      <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)"></use>
      <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)"></use>
      <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff"></use>
      </g>
      </svg>
    </div>
    </div>

    <!-- CIRCLE ANIMATION-->
    <div v-if="animation=='circles'" class="area">
      <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>

</template>

<script>
import Header from "@/components/UI/Header.vue";
import Misc from "@/functions.js"

export default {
  components:{
    'appheader':Header
  },
  data() {
    return {
      animation: Misc.animation, /* [none,waves,circles] */
      userconnected:Misc.isUserConnected(),
      path:"/",
      enteredLogin:""
    };
  },
  mounted() {
    if(this.userconnected) {
        this.enteredLogin = Misc.getConfig().login;
    }
    Misc.toggleBodyClass('addClass', 'login');
  },
  unmounted() {
    Misc.toggleBodyClass('removeClass', 'login');
  }
};
</script>

<style>
  @import '../assets/bootstrap-3/css/bootstrap.min.css';
  @import '../assets/styles/login.css';
</style>