<template>
  <div style="background-color:white">
  <div class="bar bar--dark">
    <div class="bar__inner bar__inner--footer">
      <h4 class="heading-4 heading-4--white">
        Idtonic, votre conseiller digital depuis 14 ans. Nous vous accompagnons dans l'analyse de vos besoins jusqu'à la mise en ligne. <br />
        Nous nous efforçons de trouver toujours les meilleures solutions pour votre projet.
      </h4>
    </div>
  </div>
  <div class="bar bar--white">
    <div class="bar__inner bar__inner--footer">
      <nav class="nav nav--left nav--footer">
        <ul class="nav__list nav__list--footer">
          <li class="nav__item nav__item--footer"><a class="bar__logo bar__logo--footer" href="/"><img class="bar__logo-image" src="images/logo-idt.png" alt="idtonic"></a></li>
        </ul>
      </nav>
      <nav class="nav nav--right nav--footer">
        <ul class="nav__list nav__list--footer">
          <li class="nav__item nav__item--footer"><a class="nav__link uppercase" href="workflow.php?id=0">Site web</a></li>
          <li class="nav__item nav__item--footer"><a class="nav__link uppercase" href="workflow.php?id=1">E-commerce</a></li>
          <li class="nav__item nav__item--footer"><a class="nav__link uppercase" href="workflow.php?id=2">Application</a></li>
          <li class="nav__item nav__item--footer"><a class="nav__link uppercase" href="workflow.php?id=3">Mobile</a></li>
          <li class="nav__item nav__item--footer"><a class="nav__link uppercase" href="methodologie.php">Méthodologie</a></li>
          <li class="nav__item nav__item--menu"><a class="nav__link uppercase" href="workflow.php?id=4">PWA</a></li>
          <li v-if="isLead_visitor" class="nav__item nav__item--footer"><a class="button button--green" href="https://app.idtonic.com/?t=tchat">Contact</a></li>
        </ul>
      </nav>
      <div class="h-clear"></div>
    </div>
    <div class="bar__outer">
      <span class="text"><strong>Made with ❤ in Bordeaux</strong><br> Idtonic, 20&nbsp;rte de Camblanes, 33670, Créon.</span>
    </div>
  </div>

  <!--
    POPUP BANNER
    TODO : check link (google.fr) et aussi le responsive car le popup passe dessus le texte
  

  <div v-if="isLead_visitor">
    <a href="http://google.fr" target="_blank" ><div id="page-popup" class="popup-banner popup-bottom-right fade-right" data-popup-anima="fade-right" style="cursor: pointer;display: block;transition-duration: 300ms;animation-duration: 300ms;transition-timing-function: ease;transition-delay: 0ms;">
        <i class="fa fa-times popup-close"></i>
        <div class="bs-panel panel-default">
            <div class="panel-heading">Vous avez un projet ?</div>
            <div class="panel-body">
              Prenez contact avec notre agence digitale. Tel : 05 35 54 68 93
                <span>GRATUIT</span>
            </div>
        </div>
    </div>
    </a>
  </div>
  -->
  </div>
</template>

<script>
import Misc from "@/functions.js"

export default {
  name: 'Footer',
  data() {
    return {
      isLead_visitor:Misc.isUserLead()
    };
  }
}
</script>
