<template>
<div v-if="isBasicHeader">
  <img src="images/logo_idt_square.png" class="logo">
  <div v-if="userconnected" class="beta" v-on:click="logout()">Se déconnecter</div>
</div>
<div v-else>
  <div class="bar">
    <div class="bar__inner">
      <nav class="nav nav--left">
        <li class="nav__item nav__item--menu"><input type="checkbox" id="navToggleMenu" class="nav__checkbox" value="on"><label for="navToggleMenu" class="nav__toggle-menu"></label>
          <div class="nav__menu">
            <nav class="nav__list nav__list--mobile">
              <ul>
                <li class="nav__item nav__item--menu"><router-link to="/dashboard" class="nav__link uppercase">Tableau de bord</router-link></li>
                <li class="nav__item nav__item--menu"><router-link class="nav__link uppercase" to="/foire-aux-questions">Foire aux questions</router-link></li>
                <li v-if="isLead_visitor" class="nav__item nav__item--menu"><router-link class="button" to="/contact">Contact</router-link></li>
                <li v-else class="nav__item nav__item--menu"><router-link class="nav__link uppercase" to="/contact">Contact</router-link></li>
              </ul>
            </nav>
          </div>
        </li><a class="bar__logo" href="/"><img class="bar__logo-image--top" src="/images/logo_idt_square.png" alt="idtonic"></a></nav>
      <nav class="nav nav--right nav--topbar">
        <ul class="nav__list nav__list--topbar">
          <li class="nav__item nav__item--topbar"><router-link class="nav__link uppercase" to="/dashboard">Tableau de bord</router-link></li>
          <li class="nav__item nav__item--topbar"><router-link class="nav__link uppercase" to="/foire-aux-questions">Foire aux questions</router-link></li>
          <li v-if="isLead_visitor" class="nav__item"><router-link class="button" to="/contact"></router-link></li>
          <li v-else class="nav__item nav__item--topbar"><router-link class="nav__link uppercase" to="/contact">Contact</router-link></li>
        </ul>
      </nav>
      <div class="h-clear"></div>
    </div>
  </div>
</div>
</template>

<script>
import Misc from "@/functions.js"
import {useRoute} from 'vue-router'
import {computed} from 'vue'

export default {
  name: 'Header',
  props: {
    isBasicHeader: {
      type: Boolean,
      default: true
    },
    page: {
      type: String,
      default: "home"
    }
  },
  data() {
    return {
      userconnected:Misc.isUserConnected(),
      isLead_visitor:false,
      path:"/"
    };
  },
  methods: {
    logout: function() {
        Misc.logout();
        this.$router.push('/');
    }
  },
  mounted() {
    const route = useRoute();
    this.path = computed(() =>route.path);
    if(this.userconnected) {
        console.log('App.vue > connected');
        this.isLead_visitor = Misc.isUserLead();
        //this.$router.push('/dashboard');
    } else {
        console.log('App.vue > pas connected');
        //this.$router.push('/login');
    }
  }
}
</script>
