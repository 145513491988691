<template>
  <Header :isBasicHeader="false"></Header>
    <section class="hero">
    <div class="hero__inner">
      <div class="hero__content">
        <h1 class="heading-1 heading-1--bold hero__title"><span class="heading-1__highlight">FAQ</span></h1>
        <h3 class="heading-3 heading-3--light hero__subtitle"><span class="heading-2__highlight">Avis et réponses aux questions courantes</span></h3>
      </div>
    </div>
  </section>
  <section class="features-entry" style="background: #fff;font-size: 14px;line-height: 20px;">
    <div class="backdrop"></div>
    <div class="features-entry__inner">
      
      <h3 class="heading-3 features-entry__title" style="text-transform:capitalize">Développement</h3>
      <Accordion :items="myFAQitemsDEV" />

      <h3 class="heading-3 features-entry__title" style="text-transform:capitalize">Intégration</h3>
      <Accordion :items="myFAQitemsINT" />

      <h3 class="heading-3 features-entry__title" style="text-transform:capitalize">Assistance</h3>
      <Accordion :items="myFAQitemsASSIST" />

    </div>
    <div style="margin-top:15px;text-align:center"><router-link to="/contact" class="button button--small button--light uppercase--small">Vous n'avez pas la réponse à votre question ?</router-link></div>
  </section>
  <Footer />
</template>

<script>
import Header from "@/components/UI/Header.vue"
import Footer from "@/components/UI/Footer.vue"
import Accordion from '@dzangolab/vue-accordion'
import '@dzangolab/vue-accordion/dist/accordion.css' // import stylesheet

export default {
  name:"FAQ",
  components: {
    Header,
    Footer,
    Accordion
  },
  data() {
    return{
      myFAQitemsDEV: [
                {
                  title: 'How many time zones are there in all?',
                  content: 'Given a 24-hour day and 360 degrees of longitude around the Earth'
                },
                {
                  title: 'How long is a day and year on Venus?',
                  content: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.'
                }
              ],
      myFAQitemsINT: [
                {
                  title: 'How long is a day and year on Venus?',
                  content: 'Venus takes 224.7 Earth days to complete one orbit around the Sun.'
                }
              ],
      myFAQitemsASSIST: [
                {
                  title: 'Assistance sur votre ordinateur',
                  content: "L'assistance technique à l'aide de TeamViewer QuickSupport pour une prise de contrôle à distance rapide, c'une référence dans son domaine pour sa simplicité et sa stabilité."
                }
              ]
      }
  }
}
</script>

<style scoped>
  @import '../assets/bootstrap-3/css/bootstrap.min.css';
  @import '../assets/styles/styles.css';
</style>