<template>
<appheader isBasicHeader></appheader>
<div class="container">
  <div class="row">
      <div class="col-xs-12 col-sm-11 col-sm-offset-1 col-md-11 col-md-offset-1 ">
        <div class="context">
          <div class="main">
              <h1>Merci</h1>
              <h2>Nous accusons bonne réception de votre projet</h2>
              <h2>Nous sommes ravis d'étudier votre projet, nous mettons tout en <br />
                oeuvre pour vous apporter une réponse dans les meilleurs délais.</h2>
              <h2>N'hésitez pas à revenir vers nous si vous avez besoin d'informations <br />
                supplémentaires, veuillez me contacter à l'adresse suivante : <br />
                <a href="mailto:d.marchesson@idtonic.com">d.marchesson@idtonic.com</a>.</h2>
              <hr>
              <div class="col-xs-12 center-block text-center">
                <router-link class="btn btn-success" to="/">Espace projet</router-link>
              </div>
            </div>
        </div>
      </div>
  </div>
</div>
</template>

<script>
import Header from "@/components/UI/Header.vue";
import Misc from "@/functions.js"
import {confetti} from "@/assets/animations/confetti.js"

export default {
  components:{
    'appheader':Header,
  },
  mounted() {
    Misc.toggleBodyClass('addClass', 'login');
    confetti.start(750);
  },
  unmounted() {
    Misc.toggleBodyClass('removeClass', 'login');
  }
};
</script>

<style>
  @import '../assets/bootstrap-3/css/bootstrap.min.css';
  @import '../assets/styles/login.css';
</style>