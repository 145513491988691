<template>
    <Header :isBasicHeader="false"></Header>
    <section class="hero">
    <div class="hero__inner">
      <div class="hero__content">
        <h1 class="heading-1 heading-1--bold hero__title"><span class="heading-1__highlight">Restons en contact !</span></h1>
        <!-- <h3 class="heading-3 heading-3--light hero__subtitle"><span class="heading-2__highlight"></span></h3> -->
      </div>
    </div>
  </section>
    <section class="page-entry" style="background: #fff;font-size: 14px;line-height: 20px;">
        <div class="backdrop"></div>
        <div class="features-entry__inner">
            <h3 class="heading-3 features-entry__title">Un problème ou vous avez besoin d'information ?</h3>
            <p>Envoyez-moi simplement un email <a href="mailto:d.marchesson@idtonic.com" style="display:inline">d.marchesson@idtonic.com</a>, je vous répondrai dans les meilleurs délais.</p>
            <p>Vous pouvez aussi me contacter par téléphone : 05 35 54 68 93 - 06 14 15 61 09</p>
            <h3 class="heading-3 features-entry__title">Vous avez un projet de site web ou d'app mobile ?</h3>
            <p>Votre projet est prêt et n'attend plus que sa réalisation, remplissez ce questionnaire et vous obtiendrez une réponse précise et rapide.</p>
            <div style="margin-top:15px;"><router-link to="/tchatbot" class="button button--small button--light uppercase--small">Planificateur de projet</router-link></div>
        </div>
    </section>
    <Maintenance />
    <Footer />
</template>

<script>
import Header from "@/components/UI/Header.vue";
import Footer from "@/components/UI/Footer.vue";
import Maintenance from "@/components/UI/Maintenance.vue";

export default {
    name: "Contact",
    components: {
        Header,
        Maintenance,
        Footer,
    },
};
</script>

<style>
@import "../assets/bootstrap-3/css/bootstrap.min.css";
@import "../assets/styles/styles.css";
</style>